import { MailIcon, PhoneIcon } from '@heroicons/react/outline'

export default function App() {
  return (
    <div>
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-600 sm:text-4xl">
          <span className="block">Hyperkliv AB</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-600">
          Welcome to the temporary internet home of my newly created company. My goal is to create standardized IT systems with small carbon footprints. Feel free to reach out to me via the details below. I would love to have a chat about how I can help your company.
        </p>
        <p className="mt-4 text-lg leading-6 text-gray-600">
          – Jacob Magnusson, founder of Hyperkliv AB
        </p>
        <a
          href="mailto:jacob@hyperkliv.se"
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-100 bg-gray-800 hover:bg-gray-600 sm:w-auto"
        >
          <MailIcon className="h-6 w-6 mr-2" aria-hidden="true" /> jacob@hyperkliv.se
        </a>
        <span className="m-1"></span>
        <a
          href="tel:+46706205854"
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-100 bg-gray-800 hover:bg-gray-600 sm:w-auto"
        >
          <PhoneIcon className="h-6 w-6 mr-2" aria-hidden="true" /> +46 (0)70 620 58 54
        </a>
      </div>
    </div>
  )
}
